import { type node } from "@/types";
import { useStaticQuery, graphql, Link } from "gatsby";
import React from "react";
import { useLocation } from "react-use";

const Capabilities = () => {
  const { pathname } = useLocation();
  const isHomePath = pathname === "/";

  const data = useStaticQuery(graphql`
    query {
      allServicesJson {
        edges {
          node {
            id
            name
            description
            image
            services {
              name
              slug
            }
          }
        }
      }
    }
  `);

  return (
    <div
      className={`rts-cap-area rts-bg pt--0 ${
        !isHomePath ? "pb--60" : "home pb--80"
      }`}
    >
      <div
        className={`container ${!isHomePath ? "pt--40" : "pt--25 mr--0 ml--0"}`}
      >
        <div className="cap-card-wrapper">
          {data.allServicesJson.edges.map(({ node }: node) => (
            <div key={node.id} className="cap-card">
              <div className="cap-card-ct">
                <img
                  src={`/images/services/${String(node.image)}`}
                  alt="Capability Image"
                />
              </div>
              <div className="cap-wp">
                <div className="title-wp">
                  <h4
                    className={`mb--10${
                      node.description.length < 20 ? " c-padding" : ""
                    }${
                      node.description.length === 30 ||
                      node.description.length === 38
                        ? " cp-padding"
                        : ""
                    }${node.description.length !== 58 ? " sm-padding" : ""}`}
                  >
                    {node.description}
                  </h4>
                  <h6 className="cap-title">{node.name}</h6>
                </div>
                <div className="content-wp cap-card-padding">
                  <hr className="cap-divider mt--20" />
                  <ul>
                    {node.services.map((serv, index) => (
                      <li key={`cap-${index}`} className="d-flex serv-item">
                        <div>
                          <img
                            src="/images/icon/check-icon.svg"
                            alt="Check Icon"
                            style={{ width: "16px", marginRight: "18px" }}
                          />
                        </div>
                        <span>
                          <Link
                            to={
                              [
                                "airfi-videos",
                                "mx14-videos",
                                "satto-videos",
                              ].includes(serv.slug)
                                ? `/media/videos/${serv.slug}`
                                : `/capabilities/${serv.slug}`
                            }
                          >
                            {serv.name}
                          </Link>
                        </span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Capabilities;
