// import AboutC from "@/components/About";
import Breadcrumb from "@/components/Common/Breadcrumb";
import Layout from "@/components/Layout";
import SEO from "@/components/seo";
import React from "react";
import { useLocation } from "@reach/router";
import Capabilities from "@/components/Capabilities";
import Partners from "@/components/Home/Partners";

const CapabilitiesP = () => {
  return (
    <Layout>
      <SEO title="Capabilities" canonical={`${useLocation().host}`} />
      <Breadcrumb
        name="Capabilities"
        pathName="Capabilities"
        imgUrl="/images/fotter-sub.jpeg"
      />
      <Capabilities />
      <Partners />
    </Layout>
  );
};

export default CapabilitiesP;
